.site-content {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1820px;
    padding: 50px 30px 50px;
    @media (max-width: 992px) {
        padding: 30px 0;
    }
}

.content-area,
.sidebar {
    @extend .col;
}

.overlay-filter {
    display: none;
    &.active{
        cursor: pointer;
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.2);
        display: block;
    }
}

.box-wrap{

}



@media (min-width: 1200px){
    .sidebar {
        flex: 0 0 400px;
        max-width: 400px;
        + .content-area {
            flex: 0 0 calc(100% - 400px);
            max-width: calc(100% - 400px);
        }
    }

    .filter-canvas-button {
        display: none;
    }
}

@media (max-width: 1199px) {
    .sidebar {
        position: fixed;
        height: 100vh;
        top: 0;
        left: -370px;
        background-color: #fff;
        z-index: 100;
        overflow-y: scroll;
        padding: 15px;
        max-width: 350px;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        box-shadow: 1px 0 5px #ddd;
        &.active {
            left: 0;
        }
    }

}