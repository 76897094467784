.section-wrapper{
    padding: 60px 0;
    @media (max-width: 992px){
        padding-bottom: 0;
        padding-top: 40px;
    }
    &.pb-0{
        padding-bottom: 0;
    }

    &.pt-0{
        padding-top: 0;
    }
}