@import '../../../../public/assets/scss/base/variables';
.heading-title{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .heading-decor{
        i{
            font-size: 8px;
            margin-right: 20px;
            color: $primary;
        }
    }

    h3{
        margin-bottom: 0;
        @media (max-width: 992px) {
            font-size: 26px;
        }
    }

    .sub-title{
        color: $primary;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        font-family: $font-family-heading;
        margin-top: 10px;
    }
}